.table-date-modal .subtle {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.table-date-modal .input-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.table-date-modal .input-container .rs-input {
    flex: 1;
    margin: 0px 5px 0px 20px;
}

.table-date-modal .input-container .rs-input:first-child {
    margin-left: 0px;
}

.table-date-modal .auto-update-date {
    margin-left: -8px;
    margin-top: 12px;
}

.table-date-modal .auto-update-date .subtle {
    margin-top: 12px;
}