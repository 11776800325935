.messageBox {
    color: black;
    position: absolute;
    left: -250px;
    top: 52px;
    z-index: 6;
    width: 287px;
    border-radius: 6px;
    background-color: #D0D3D6;
}
.messageBox_arrow {
    position: absolute;
    right: 15px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 10px 6px;
    border-color: transparent transparent #D0D3D6 transparent;
}
.messageBox_switcher {
    padding-left: 12px;
    background-color: #D0D3D6;
    display: flex;
    border-radius: 6px;
}
.messageBox_switcher:hover {
    cursor: pointer;
}
.messageBox_switcher input {
    display: none;
}
.messageBox_switcher input ~ div {
    width: 45px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-weight: regular;
    font-size: 15px;
}
.messageBox_switcher input:checked ~ div {
    border-bottom: 3px solid #00819C;
    color: #00819C;
}
.messageBox_switcher .switcher_tab > div:nth-of-type(1) {
    cursor: pointer;
}
.messageBox_content {
    border-radius: 6px;
    overflow: hidden;
    background-color: #DADDE0;
}

.messageBox_content_task:first-child{
    margin-top: 12px;
}

.messageBox_content_task_row {
    font-size: 15px;
    width: 255px;
    height: 42px;
    margin: auto;
    color: #141824;
    opacity: 0.5;
}
.messageBox_content_task_row > div {
    border-bottom: 1px solid #23181521;
    display: flex;
    justify-content: space-between;
}
.messageBox_content_task_row_title {
    display: flex;
}
.messageBox_content_task_row_title > div:nth-of-type(1) {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messageBox_content_task_row_title > div:nth-of-type(1) .rs-icon > svg {
    width: 12px;
    height: 12px;
}
.messageBox_content_task_row_title a {
    color: #141824;
    text-decoration: none;
}
.messageBox_content_task_row_title a:hover {
    color: #141824;
    text-decoration: none;
}
.messageBox_content_task_row_title a:focus {
    text-decoration: none;
}
.messageBox_content_task_row_status {
    word-break: keep-all;
}
.messageBox_content_task_row-unread {
    opacity: 1;
    color: #141824;
}
.messageBox_content_task_row-unread a {
    color: #141824;
    text-decoration: none;
}
.messageBox_content_task_row-unread a:hover {
    color: #141824;
    text-decoration: none;
}
.messageBox_content_task_row-unread a:focus {
    text-decoration: none;
}
.messageBox_content_email_row {
    display: block;
    width: 255px;
    height: 42px;
    margin: auto;
    opacity: 0.5;
}
.messageBox_content_email_row:hover {
    color: #141824;
    text-decoration: none;
}
.messageBox_content_email_row > div {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #23181521;
}
.messageBox_content_email_row-unread {
    opacity: 1;
}
.messageBox_content_email_row-unread .messageBox_content_email_row_contents > div:nth-of-type(1) {
    color: #141824;
}
.messageBox_content_email_row_title {
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messageBox_content_email_row_title .rs-icon > svg {
    width: 26px;
    height: 26px;
}
.messageBox_content_email_row_contents > div:nth-of-type(1) {
    color: #141824;
    font-size: 15px;
}
.messageBox_content_email_row_contents > div:nth-of-type(2) {
    color: rgba(20, 24, 36, 0.4);
    font-size: 11px;
}
.messageBox_read_control {
    display: flex;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
}
.messageBox_read_control button {
    font-size: 12px;
    color: #00819C;
    background-color: transparent;
    font-weight: regular;
}
