.table-page .block-1 {
    position: relative;
}

.table-page .button-container {
    display: flex;
}

.table-page .button-container .left-button-container {
    display: flex;
    flex: 1;
    margin-left: -12px;
    align-items: center;
}

.table-page .button-container .left-button-container .rs-btn-link {
    display: flex;
    align-items: center;
}

.table-page .button-container .left-button-container .rs-btn-default {
    margin-left: 10px;
}

.table-page .button-container .left-button-container .rs-btn-link .rs-icon,
.table-page .hint .rs-btn-link .rs-icon {
    margin-right: 4px;
}

.table-page .button-container .right-button-container {
    display: flex;
    align-items: center;
}

.table-page .rs-dropdown a {
    color: #ffffff;
}

.table-page .rs-dropdown a:hover {
    text-decoration: unset;
}

.table-page .right-button-container .rs-dropdown-toggle {
    border: 0px;
    background: transparent;
    color: #00ddff !important;
    font-size: 14px;
    padding-top: 4px;
    padding-right: 12px;
    padding-left: 0px;
}

.table-page .right-button-container .rs-dropdown-toggle-caret {
    right: 0px;
    top: 4px;
}

.table-page .date-button {
    display: flex;
    padding-right: 0px;
}

.table-page .update-date-button {
    font-size: 14px;
    color: #ffffff;
    margin-left: 2px;
    padding-left: 0px;
}

.table-page .table-container {
    overflow: auto;
    height: calc(100vh - 220px);
    margin-top: 20px;
    display: flex;
}

.table-page .hint {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.table-page .hint .subtle {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
}

.table-page .hint .rs-btn {
    display: flex;
    align-items: center;
}

.table-page .hint .rs-btn,
.table-page .hint .rs-icon {
    font-size: 23px;
}

.table-page .hint .rs-icon > svg {
    height: 23px;
}

.table-page .hint.overlay {
    position: absolute;
    bottom: 50px;
    left: 50%;
}