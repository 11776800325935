.table-manager-modal .list-panel {
    height: 419px;
    overflow-y: auto;
}

.table-manager-modal .list-panel .row {
    display: flex;
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
    align-items: center;
    height: 35px;
    padding: 0px 7px;
    cursor: pointer;
}

.table-manager-modal .list-panel .row:hover {
    background: rgba(0, 129, 156, 0.5);
}

.table-manager-modal .list-panel .list-header {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
    display: flex;
    font-size: 14px;
    padding: 0px 7px 7px 7px;
}

.table-manager-modal .list-panel .name {
    flex: 1;
}

.table-manager-modal .list-panel .rename {
    width: 56px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.table-manager-modal .list-panel .rename .rs-icon use {
    fill: rgba(255, 255, 255, 0.5);
}

.table-manager-modal .list-panel .remove {
    width: 28px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.table-manager-modal .header {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 7px;
}