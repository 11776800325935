
.preview-grid {
    background: rgba(20, 24, 36, 0.3);
    border: 1px solid rgba(0, 221, 255, 0.5);
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.preview-grid .remove {
    position: absolute;
    top: 5px;
    right: 5px;
}