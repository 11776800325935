// inPopover mode
.track__stock__toggle {
    .track-stock-toggle-list {
        top: 33px !important;
        left: 8px !important;

        .rs-popover-arrow {
            visibility: unset;
        }

        .rs-popover-content {
            margin: 0px;
        }
    }
}

.track-stock-toggle-list {
    .rs-popover-content {
        margin: 0px;

        .list-header {
            color: rgba(255, 255, 255, 0.7);
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 8px;
        }

        .list .row {
            font-size: 16px;
            display: flex;
            align-items: center;
            margin: -8px 0px -8px -8px;
            padding: 0px;

            &:first-child {
                padding-top: 0px;
            }
        }
    }
}