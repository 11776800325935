.stock-selection-modal .sub-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 9px;
}

.stock-selection-modal .sub-text.multiple {
    margin-top: 20px;
}

.stock-selection-modal.rs-modal {
    width: 1282px;
}

.stock-selection-modal .rs-modal-body {
    display: flex;
}

.stock-selection-modal .group {
    width: 540px;
    margin-right: 10px;
}

.stock-selection-modal .mode-picker {
    width: 100%;
}

.stock-selection-modal .group .input-panel {
    margin-top: 26px;
}

.stock-selection-modal .group .group-list {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(20, 24, 36, 0.3);
    height: 488px;
    display: flex;
    flex-direction: column;
}

.stock-selection-modal .group-list .rs-tree {
    max-height: unset;
}

.stock-selection-modal .group-list .rs-tree .custom-stock-group {
    display: flex;
    align-items: center;
}

.stock-selection-modal .group-list .rs-tree .custom-stock-group .custom-group-name {
    margin-right: 10px;
}

.stock-selection-modal .multiple-input textarea {
    height: 374px;
    max-height: unset;
}

.stock-selection-modal .multiple-input .rs-input-group-btn {
    align-self: flex-start;
}

.stock-selection-modal .strategy-picker {
    flex: 1;
    margin: 0px 10px;
}

.stock-selection-modal .score-operator-picker {
    width: 200px;
    margin-left: 10px;
}

.stock-selection-modal .score-input-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.stock-selection-modal .score-input {
    margin: 0px 10px;
    flex: 1;
}

.stock-selection-modal .rule-row {
    display: flex;
    margin-bottom: 10px;
}

.stock-selection-modal .rule-row .button-column {
    width: 35px;
    text-align: center;
}

.stock-selection-modal .rule-row .button-column .rs-btn {
    width: 35px;
    padding: 0 !important;
    background: transparent;
}

.stock-selection-modal .rule-row .index-expression {
    margin-left: 10px;
}

.stock-selection-modal .index-button-container {
    display: flex;
}

.stock-selection-modal .index-button-container .left-button {
    flex: 1;
    margin-left: -12px;
}

.stock-selection-modal .index-button-container .start-filter-button {
    margin-right: -12px;
}

.stock-selection-modal .index-button-container .filter-status {
    font-size: 14px;
    margin-top: 8px;
}

.stock-selection-modal .preview {
    width: 270px;
    margin: 0px 10px;
}

.stock-selection-modal .stock-container {
    background: rgba(20, 24, 36, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 488px;
    display: flex;
    flex-direction: column;
}

.stock-selection-modal .stock-list {
    flex: 1;
    overflow: auto;
}

.stock-selection-modal .stock-list .stock {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.stock-selection-modal .stock-list .stock .rs-icon {
    margin-left: 15px;
}

.stock-selection-modal .stock:hover {
    background: #3c3f43;
}

.stock-selection-modal .stock-name {
    flex: 1;
}

.stock-selection-modal .selection {
    width: 432px;
    margin-left: 10px;
}

.stock-selection-modal .add-all-button,
.stock-selection-modal .clear-button,
.stock-selection-modal .add-stock-group-button {
    margin-left: -12px;
}