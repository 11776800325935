.message {
    position: relative;
    width: 60px;
    margin-right: 12px;
}
.message__icon {
    cursor: pointer;
}
.message_arrow {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    transform: scale(0.7);
}
.message__container__switcher {
    display: flex;
}
