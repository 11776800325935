.stock-holdings-table {
    .cell {
        font-size: 14px;
        white-space: nowrap;
        cursor: pointer;
        padding: 13px 10px;

        &.header-cell {
            color: rgba(255, 255, 255, 0.5);
        }

        &.selected-stock {
            border: 3px solid #00ddff;
            padding: 10px 7px;
        }
    }

    .row.odd {
        background: #3f434f;
    }
}

.popover-stock-holdings .rs-popover-arrow {
    visibility: hidden;
}

.popover-stock-holdings.rs-popover {
    border-radius: 0px;
    width: 428px;
    border: 1px solid #fff;
    background: #141824;
}

.popover-stock-holdings.rs-popover.expand {
    width: 800px;
}

.popover-stock-holdings .rs-popover-content {
    margin: -12px;
    font-size: 16px;
}

.popover-stock-holdings .stock-name {
    font-size: 18px;
    cursor: pointer;
}

.popover-stock-holdings .row {
    padding: 10px;
    display: flex;
}

.popover-stock-holdings .bottom-line {
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
}

.popover-stock-holdings .column-container {
    display: flex;
}

.popover-stock-holdings .column-container .column {
    flex: 1;
}

.popover-stock-holdings .top-line {
    border-top: 0.25px solid rgba(255, 255, 255, 0.15);
}

.popover-stock-holdings .expand-text {
    cursor: pointer;
}

.popover-stock-holdings .expand-text .rs-icon {
    margin-left: 5px;
}

.popover-stock-holdings .detail {
    padding: 10px;
    padding-top: 0px;
    font-size: 14px;
}

.popover-stock-holdings .category {
    border-top: 0.25px solid rgba(255, 255, 255, 0.15);
}

.popover-stock-holdings .category:last-child {
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
}

.popover-stock-holdings .category-name,
.popover-stock-holdings .rule {
    padding-top: 5px;
    padding-bottom: 5px;
}

.popover-stock-holdings .rule {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 0.25px solid rgba(255, 255, 255, 0.15);
}

.popover-stock-holdings .rule .index,
.popover-stock-holdings .rule .operand {
    flex: 4;
}

.popover-stock-holdings .rule .operator {
    flex: 2;
}

.popover-stock-holdings .rule .score {
    display: flex;
    flex: 1;
    padding-left: 10px;
}

.popover-stock-holdings .rule .score.full {
    color: #0df !important;
}