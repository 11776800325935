.upload-panel .dropzone {
    height: 488px;
    background: rgba(20, 24, 36, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-panel .dropzone:focus {
    outline: none;
}

.upload-panel .dropzone .icon-container {
    display: flex;
}

.upload-panel .dropzone .rs-icon {
    margin: 10px;
}