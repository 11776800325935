.backtest-setting-panel {
    padding: 10px 0px;

    hr {
        margin: 16px 0px;
    }

    .custom-weight-hint {
        padding: 0px 10px;
        margin-bottom: 12px;
    }

    .advanced-settings-button {
        font-size: 16px;
        padding: 10px;
        margin-top: -10px;

        &:focus {
            text-decoration: none;
            color: #00ddff;
        }
    }

    .column-container,
    .custom-weight-container,
    .advanced-settings {
        display: flex;
        padding: 0px 10px;

        .column {
            flex: 1;

            .row {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-top: 10px;
                }

                .title {
                    flex: 1;
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 16px;
                    margin: 0;
                    max-width: 159px;
                }

                .value {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    max-width: 323px;

                    .rs-input,
                    .rs-picker-select,
                    .rs-picker-date {
                        flex: 1;
                    }
                }
            }
        }

    }

    .buy-rule-picker {
        margin-right: 10px;
    }

    .buy-rule-picker.rs-picker-select,
    .timespan-picker.rs-picker-select.custom {
        width: 105px;
        flex: unset;
    }

    .custom-timespan-container {
        display: flex;
        flex: 1;
    }

    .timespan-start-picker {
        margin-left: 10px;
        margin-right: 10px;
    }

    .timespan-end-picker {
        margin-left: 10px;
    }

    .backtest-date-month-picker {
        margin: 0px 8px;
    }

    .backtest-date-day-picker {
        margin-right: 8px;
    }

    .backtest-date-month-picker,
    .backtest-date-day-picker {
        min-width: 72px;
    }
}