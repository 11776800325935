.login-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -70px;
}

.login-page .login-container .logo-large {
    height: 149.04px;
    font-size: 149.04px;
    margin-bottom: 42px;
}

.login-page .login-container .logo-large > svg {
    height: 149.04px;
}

.login-container .split {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}

.login-container .split-line {
    height: 1px;
    width: 50px;
    background: rgba(255, 255, 255, 0.7);
    flex: 1;
}

.login-page .login-container .split-line.left {
    margin-right: 8px;
}

.login-page .login-container .split-line.right {
    margin-left: 8px;
}

.login-container .split span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 7.62px;
}

.login-page .login-container .logo-cnyes {
    height: 23.8px;
    font-size: 23.8px;
}

.login-page .login-container .logo-cnyes > svg {
    height: 23.8px;
}

.login-page .login-button {
    width: 301px;
    height: 37px;
}

.login-page .buttons > div:not(:first-child) {
    margin-top: 30px;
}

.login-page .buttons > div {
    display: flex;
    justify-content: center;
}

.login-page .loading {
    text-align: center;
    margin-top: 10px;
    font-size: 24px;
}