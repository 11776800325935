.score-bar {
    display: flex;
    flex: 1;
    position: relative;

    .name {
        display: flex;
        position: absolute;
        left: 9px;
        top: 2.6px
    }

    .bar {
        flex: 1;
    }
    
    .value {
        padding-left: 25px;
        min-width: 67px;
        text-align: end;

        .score {
            font-size: 18px;
            font-weight: bold;
        }

        .total {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 2px;
        }
    }
}