.backtest .button-container {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .rs-btn:not(:first-child) {
        margin-left: 13px;
    }
}

.backtest .stock-score-table {
    padding: 10px;
    margin-top: 10px;
}

.backtest {
    &__title {
        display: flex;
        margin-bottom: 7px;
    }

    .step-container {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: -10px;
    }
}