.strategy_list__subtitle {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
}
.strategy_list__subtitle__unit {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    height: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 42px;
    box-sizing: border-box;
}
.strategy_list__subtitle__unit > div:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    font-weight: normal;
}
.strategy_list__subtitle__unit > div:nth-of-type(2) {
    font-size: 16px;
}
.strategy_list__subtitle__unit > div:nth-of-type(2) .red {
    color: #FF4A68;
}
.strategy_list__subtitle__unit > div:nth-of-type(2) .green {
    color: #27CF00;
}
.strategy_list__switch {
    padding-right: 9px;
    align-self: flex-end;
    margin-left: auto;
}
.strategy_list__switch .open {
    transform: rotate(180deg) translateY(2px);
}
.strategy_list__switch button {
    background-color: transparent;
    display: flex;
    padding: 0;
}
.strategy_list__switch button > div:nth-of-type(1) {
    padding-top: 2px;
    padding-left: 2px;
    width: 25px;
    height: 25px;
    background-color: #00819C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.strategy_list__switch button > div:nth-of-type(2) {
    border: solid rgba(255, 255, 255, 0.2);
    border-width: 1px 1px 1px 1px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 23px;
}
