////
///  @group RootColor
///  原色
////

$white-1: #FFFFFF;

$grey-1: rgba(255, 255, 255, 0.5);
$grey-2: rgba(255, 255, 255, 0.7);

$blue-1: #00DDFF;

$dark-1: #2B2F3B;
$dark-2: #212531;
$dark-3: rgb(0, 11, 22);

$yellow-1: #FF9600;
$yellow-2: #FFF000;