.strategy-selector {
    user-select: none;
}

.strategy-selector .row {
    display: flex;
}

.strategy-selector hr {
    margin-top: -3px !important;
}

.strategy-selector #strategy-list {
    display: flex;
    overflow-x: auto;
    width: 0;
    flex: 1;
    overscroll-behavior: none;
}

.strategy-selector #strategy-list::-webkit-scrollbar-thumb {
    visibility: collapse;
}

.strategy-selector #strategy-list .strategy-name {
    white-space: nowrap;
    padding: 10px;
    padding-bottom: 13px;
    margin-bottom: 2px;
    cursor: pointer;
}

.strategy-selector #strategy-list .strategy-name:hover {
    background: #242733;
}

.strategy-selector #strategy-list .strategy-name.selected {
    color: #00ddff;
    border-bottom: 3px solid #00ddff;
    padding-bottom: 5px;
    margin-bottom: 2px;
}

.strategy-selector .picker {
    display: flex;
    justify-items: center;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 5px;
    width: 150px;
}

.strategy-selector .picker .rs-picker-select {
    width: 100%;
}