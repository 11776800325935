.index-library-modal .indexTime {
    display: flex;
    white-space: nowrap;
    align-items: center;
}

.index-library-modal .sub-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
}

.index-library-modal .treeContainer {
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 351px;
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.index-library-modal .treeCustomInput {
    display: flex;
    align-items: center;
    padding: 4px 14px;
    background: rgba(20, 24, 36, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.index-library-modal .treeCustomInput .rs-input {
    border: 0;
    border-bottom: 1px solid #fff;
    background: transparent;
    width: 70px;
    height: 23px;
    text-align: center;
}

.index-library-modal .treeContainer .rs-picker-none{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.index-library-modal .advance {
    font-size: 16px;
    align-self: flex-end;
    padding-right: 0px;
}

.index-library-modal .advance:hover {
    text-decoration: none;
}

.index-library-modal .advance .rs-icon {
    margin-left: 5px;
}

.index-library-modal .advance .rs-icon use {
    stroke: currentColor;
}