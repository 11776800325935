@import "Style/util.scss";
@import "Style/color/border.scss";
@import "Style/color/font.scss";
.strategy-overview {
    & > div:nth-of-type(1) {
        display: flex;
        margin-bottom: 7px;
    }
    &__pagination {
        text-align: center;
        margin-top: 16px;
        height: 40px;
    }
    
    &-empty {
        background: #2b2f3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 533px;

        .subtle {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
        }

        .text {
            font-size: 18px;
            color: #ffffff;
        }
    }
}

.strategy {
    &_overview {
        &__sec_title {
            display: flex;
            &__switcher {
                display: flex;
                margin-top: 23px;
                margin-bottom: 7px;
                color: $font-grey-2;
                font-size: 14px;
                & > div {
                    @include centerY();
                    cursor: pointer;
                }
                & > div:nth-of-type(1) {
                    border-left: 1px solid $border-grey-1;
                    margin-left: 23px;
                    margin-right: 23px;
                    height: 21px;
                    align-self: center;
                }
                
                & > div:nth-of-type(2) {
                    margin-right: 13px;
                }
                
                & > div:nth-of-type(3) {
                    width: 47px;
                    @include betweenX();
                    & > div {
                        @include centerY();
                    }
                }
            }
        }
    }
}


.strategy-overview .rs-table-row:nth-child(odd) .rs-table-cell-group-fixed-right,
.strategy-overview .rs-table-row:nth-child(odd) .rs-table-cell-group-fixed-left {
    background: #353945 !important;
}

.strategy-overview .rs-table-row:nth-child(even) .rs-table-cell-group-fixed-right,
.strategy-overview .rs-table-row:nth-child(even) .rs-table-cell-group-fixed-left {
    background: #2d313d !important;
}

.strategy-overview .rs-table-row:hover .rs-table-cell-group-fixed-right,
.strategy-overview .rs-table-row:hover .rs-table-cell-group-fixed-left {
    background: #365264 !important;
}

.strategy-overview .rs-table-row-header .rs-table-cell-group-fixed-right .rs-table-cell,
.strategy-overview .rs-table-row-header .rs-table-cell-group-fixed-left .rs-table-cell {
    background: #2e303d !important;
}

.strategy-overview .disabled {
    color: #6d6f78;
    cursor: not-allowed;
}

.strategy-overview .disabled .rs-icon use {
    fill: currentColor;
}

.strategy-overview .rs-icon use {
    fill: #00ddff;
}

.strategy-overview .rs-btn.toggle {
    margin-top: -5px;
    width: 70px;
    
    .rs-icon {
        margin-right: 4px;
        font-size: 14px;

        > svg {
            height: 14px;
            margin-bottom: -2px;
        }
    }
}

.strategy-overview .rs-table-cell.more-cell {
    overflow: visible;

    .rs-table-cell-content {
        overflow: visible;
    }
}

.strategy-overview .more {
    cursor: pointer;
    display: flex;
    background: #141824;
    padding: 5px 15px;
    border-radius: 20px;
    margin-top: -6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
}

.strategy-overview .more .more-text:hover {
    color: #baefff;
}

.strategy-overview .more .more-item {
    display: flex;
    position: absolute;
    left: -321px;
    top: -1px;
    background: #141824;
    padding: 5px 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-right: transparent;
    visibility: collapse;
}

.strategy-overview .more.expanded .more-item {
    visibility: visible;
}

.strategy-overview .more .more-item > * {
    margin-left: 15px;
}

.strategy-overview .more .more-item > *:first-child {
    margin-left: 0px;
}

.strategy-overview .more .more-item > *:not(.disabled):hover {
    text-decoration: none;
    color: #baefff;
}

.strategy-overview .more .more-item > *:not(.disabled):hover .rs-icon use {
    fill: #baefff;
}