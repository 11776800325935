html,
body,
#root {
    height: 100%;
    color: #fff;
    font-size: 16px;
    background: #141824;
}

.page-title {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    flex: 1;
    align-self: center;
}

.page-subtitle {
    font-size: 16px;
    font-weight: normal;
    margin-left: 15px;
    color: rgba(255, 255, 255, 0.7);
}

hr {
    margin: 0px;
    border-top: 0.25px solid rgba(255, 255, 255, 0.15);
}

.title {
    font-size: 20px;
    margin: 23px 0px 7px 0px;
}

.block {
    background: #2d313d;
}

.block-1 {
    background: #2d313d;
    padding: 12px;
}

.block-2 {
    background: #353945;
    padding: 12px;
}

.red,
.red a {
    color: #ff4a68 !important;
}

.green,
.green a {
    color: #72cf00 !important;
}

.blue,
.blue a {
    color: #00ddff !important;
}

.yellow,
.yellow a {
    color: yellow !important;
}

.orange,
.orange a {
    color: #ff9600 !important;
}

.grey,
.grey a {
    color: rgba(255, 255, 255, 0.5) !important;
}

.purple,
.purple a {
    color: #cc6dff !important;
}

.bg-red {
    background: #ff4a68;
    color: #353945;
    font-weight: bold;
    padding: 0px 4px;
    width: fit-content;
}

.bg-green {
    background: #72cf00;
    color: #353945;
    font-weight: bold;
    padding: 0px 4px;
    width: fit-content;
}

.opacity-40 {
    opacity: 0.4;
}

.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
}

.rs-btn.rs-btn-default.btn-orange {
    background: #b87400;
}

.rs-btn.rs-btn-default.btn-orange:hover {
    background: #fbab00;
}

.rs-btn.rs-btn-default.btn-grey {
    background: #676c76;
}

.rs-btn.rs-btn-default.btn-grey:hover {
    background: #7d838f;
}

.rs-btn.rs-btn-disabled {
    background: #555555 !important;
}

.rs-btn.rs-btn-disabled:hover {
    color: #fff;
}

.rs-btn.rs-btn-default {
    background: #00819c;
    color: #fff;
    font-size: 18px;
    padding: 3px 30px;
    border-radius: 3px;
}

.rs-btn.rs-btn-default:hover {
    background: #48a8c3;
}

.rs-btn.toggle {
    border-radius: 0px;
    background: transparent;
    font-size: 14px;
    padding: 2px 10px;
}

.rs-btn.toggle.on {
    color: #00ddff;
    border: 1px solid #00ddff;
}

.rs-btn.toggle.on:hover {
    background: rgba(0, 0, 0, 0.2);
}

.rs-btn.toggle.off {
    color: #999;
    border: 1px solid #999;
}

.rs-btn.toggle.off:hover {
    color: #fff;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.2);
}

.rs-btn-link {
    color: #00ddff;
}

.rs-modal-body>.rs-btn-subtle {
    padding: 0;
    font-size: 16px;
    color: #fff;
    border-radius: 0;
}

.rs-btn-subtle>i {
    font-size: 16px;
    margin-left: 1px;
}

.rs-picker-default .rs-picker-toggle {
    border-radius: 0;
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(20, 24, 36, 0.3) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #fff;
}

.rs-btn.rs-btn-default.rs-picker-toggle {
    padding: 4px 28px 4px 7px;
}

.rs-picker-toggle-value {
    font-size: 16px;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 3px;
}

.rs-dropdown-toggle {
    font-size: 16px;
    color: #fff !important;
}

div>.rs-dropdown-toggle {
    border: rgba(255, 255, 255, 0.5) 1px solid;
    border-radius: 0px;
    padding-top: 3px;
    padding-bottom: 4px;
    padding-right: 49px;
}

.rs-dropdown-toggle-caret {
    top: 3px;
    right: 10px;
}

div>.rs-dropdown-menu {
    border: rgba(255, 255, 255, 0.5) 1px solid;
    border-radius: 0;
    margin-top: 5px;
    padding: 0;
    background: #141824;
}

div>.rs-dropdown-menu .rs-dropdown-item,
.rs-dropdown-menu .rs-dropdown-item-divider {
    margin: 0;
}

.rs-input {
    border-radius: 0px;
    border-color: rgba(255, 255, 255, 0.5);
    padding: 4px 12px !important;
    background: rgba(20, 24, 36, 0.3);
    font-size: 16px;
}

.rs-input::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 16px;
}

.rs-input-group {
    border-radius: 0;
    background: rgba(20, 24, 36, 0.3);
}

.rs-input-group.rs-input-group-inside .rs-input+.rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-auto-complete+.rs-input-group-btn {
    color: #00ddff;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    background: #292d33;
}

.rs-input-group.rs-input-group-inside .rs-input+.rs-input-group-btn>i,
.rs-input-group.rs-input-group-inside .rs-auto-complete+.rs-input-group-btn>i {
    margin-right: 6px;
}

.rs-auto-complete-item-focus,
.rs-auto-complete-item:hover {
    color: #fff;
    background-color: #777;
}

.rs-btn.rs-btn-default.rs-input-group-btn {
    background: transparent;
    border-radius: 0;
    padding: 8px 12px;
}

.rs-input-group .rs-input {
    background: transparent;
}

.rs-tag {
    background: transparent;
    border: rgba(0, 221, 255, 0.5) 1px solid;
    padding: 1px 10px 2px 10px;
    color: #00ddff;
    min-height: 31.85px
}

.rs-tag-text {
    font-size: 16px;
}

.rs-tag-closeable {
    padding-right: 25px;
}

.rs-tag-closeable>.rs-tag-text {
    margin-right: 15px;
}

.rs-tag-icon-close {
    color: #00ddff;
    top: 5px;
}

.rs-modal-header .rs-modal-title {
    font-size: 20px;
    margin-bottom: 13px;
}

.rs-modal-content {
    border-radius: 0;
    background: #2d313d;
}

.rs-modal-content>hr {
    margin: 0px -20px;
}

.rs-check-tree,
.rs-check-tree-node>.rs-check-tree-node-label .rs-check-item .rs-checkbox-checker>label::before {
    background-color: transparent;
}

.rs-modal-body {
    margin-top: 18px;
}

.rs-modal-body>.rs-check-tree {
    margin-left: -12px !important;
}

.rs-checkbox-wrapper {
    left: 8px !important;
}

.rs-check-tree-node {
    font-size: 16px;
}

.rs-check-tree-node>.rs-check-tree-node-label .rs-check-item .rs-checkbox-checker>label {
    padding-left: 31px;
}

.rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-radius: 0;
}

.rs-check-tree-node>.rs-check-tree-node-label .rs-check-item.rs-checkbox-checked .rs-checkbox-checker>label {
    font-weight: normal;
    background: transparent;
}

.rs-checkbox-checked {
    background: transparent;
}

.rs-pagination>.rs-pagination-btn>a,
.rs-pagination>li.rs-pagination-btn-active>a {
    color: #fff;
    border-radius: 0;
}

.rs-pagination>li.rs-pagination-btn-active>a {
    background: #00819c;
}

.rs-tree {
    background: transparent;
}

.rs-tree-node:hover {
    background: #3c3f43;
}

.rs-tree-node.rs-tree-node-active {
    background: rgba(0, 129, 156, 0.5);
}

.rs-tree-node>.rs-tree-node-label {
    font-size: 16px;
    ;
}

.rs-tree-node.rs-tree-node-active .rs-tree-node-label-content {
    background: transparent;
    color: #fff;
    font-weight: normal;
}

.rs-tree-node:not(.rs-tree-node-disabled)>.rs-tree-node-label:focus>.rs-tree-node-label-content {
    background: transparent;
}

.rs-tree-node>.rs-tree-node-label>.rs-tree-node-label-content {
    padding-top: 5px;
    padding-bottom: 5px;
}

.rs-tree-node>.rs-tree-node-label>.rs-tree-node-label-content:hover {
    background-color: transparent;
}

.rs-tree.single-layer .rs-tree-node > .rs-tree-node-label {
    padding-left: 0px;
}

.rs-tree.single-layer .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    padding-left: 0px;
}

.rs-radio-wrapper .rs-radio-inner::after {
    background: #353945;
}

.rs-radio-group-picker {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0px;
}

.rs-radio-group-picker.tab {
    border: 0px;
    color: #fff;
}

.rs-radio-group-picker .rs-radio-checker>label {
    color: #fff;
    border-radius: 0px;
    padding: 5px 10px;
}

.rs-radio-group-picker.tab .rs-radio-checker>label {
    font-size: 16px;
    padding: 9px;
}

.rs-radio-group-picker .rs-radio-checked .rs-radio-checker>label {
    color: #00ddff;
    border: 1px solid #00ddff;
}

.rs-radio-group-picker.tab .rs-radio-checked .rs-radio-checker>label {
    border: 0px;
    border-bottom: 3px solid #00ddff;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    visibility: hidden;
}

.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-value,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-placeholder,
.rs-picker-daterange .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-value,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-place-holder {
    font-size: 14px;
}

.rs-table a {
    color: #fff;
}

.rs-table-cell-header .rs-table-cell .rs-table-cell-content div {
    flex: 1;
}

.rs-table-cell-group-shadow,
.rs-table-cell-group-left-shadow {
    -webkit-box-shadow: 3px 0 13px rgba(9, 9, 9, 1);
    box-shadow: 3px 0 13px rgba(9, 9, 9, 1);
}

.rs-table-cell-group-right-shadow {
    box-shadow: -3px 0 13px rgba(9, 9, 9, 1);
}

.rs-table-cell-header-sortable .rs-table-cell .rs-table-cell-content {
    display: flex !important;
    justify-content: center;
}

.rs-table-cell-group-fixed-right,
.rs-table-cell-group-fixed-left {
    background: #2b2f3b !important;
}

.rs-popover-full {
    margin-left: 13px;
}

.rs-popover-full .rs-popover-arrow {
    visibility: hidden;
}

.rs-popover-content .rs-dropdown-menu {
    margin-top: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.3);
}