.dashboard-manager-modal .rs-modal-body {
    display: flex;
}

.dashboard-manager-modal .list-panel {
    flex: 1;
    height: 419px;
    overflow-y: auto;
}

.dashboard-manager-modal .list-panel .row {
    display: flex;
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
    align-items: center;
    height: 35px;
    padding: 0px 7px;
    cursor: pointer;
}

.dashboard-manager-modal .list-panel .row.selected {
    background: rgba(0, 129, 156, 0.5);
}

.dashboard-manager-modal .list-panel .list-header {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
    display: flex;
    font-size: 14px;
    padding: 0px 7px 7px 7px;
}

.dashboard-manager-modal .list-panel .name {
    flex: 1;
}

.dashboard-manager-modal .list-panel .remove {
    width: 28px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.dashboard-manager-modal .layout-panel {
    flex: 1;
    margin-left: 30px;
    margin-right: 5px;
}

.dashboard-manager-modal .layout-panel .preview-dashboard {
    height: 419px;
    overflow: auto;
}

.dashboard-manager-modal .header {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 7px;
}