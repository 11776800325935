.strategy-list .track__stock__toggle {
    margin-right: 10px;
}
.strategy-list .smaller-font {
    font-size: 14px;
}

.strategy-list .switch {
    display: flex;
}

.strategy-list .show {
    margin-left: 16px;
    border-radius: 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 6px 10px;
    color: white;
}

.strategy-list .show:hover {
    color: #cbced4;
    background: unset;
}

.strategy-list .show.active {
    color: #00ddff;
    border: 1px solid #00ddff;
}

.strategy-list .container {
    display: flex;
}

.strategy-list .table {
    flex: 1;
    margin-right: 20px;
}

.strategy-list .rs-table-cell-header .rs-table-cell-content {
    text-align: center !important;
}

.strategy-list .rs-table-row {
    background: transparent !important;
    border-bottom: 0.25px rgba(255, 255, 255, 0.15) solid !important;
}

.strategy-list .strategy_list__contents .rs-table-cell-content {
    padding: 6px 0px;
}

.strategy-list .graph {
    width: 50%;
    padding: 10px;
    background: #232733;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.strategy-list > div:nth-of-type(2) {
    display: flex;
}