.graph-panel {
    display: flex;
    flex: 1;

    .block-title {
        font-size: 18px;
        border-bottom: 0.25px solid rgba(255, 255, 255, 0.15);
        padding: 0px 5px 11px 0px;
        margin-bottom: 11px;
    }

    .graph-column {
        flex: 2;
    }

    .result-column {
        flex: 1;
        margin-left: 40px;

        .list-panel {
            .value {
                text-align: end;
            }
        }
    }
}