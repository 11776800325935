.index-library-modal-table.rs-modal {
    width: 1279px;
}

.index-library-modal-table .rs-modal-body {
    display: flex;
}

.index-library-modal-table .rs-input-group:not(.rs-input-group-inside) {
    border: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.index-library-modal-table .sub-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 11px;
}

.index-library-modal-table .treeContainer {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(20, 24, 36, 0.3);
    height: 412px;
    display: flex;
    flex-direction: column;
}

.index-library-modal-table .treeContainer .rs-tree {
    max-height: unset;
}

.index-library-modal-table .treeCustomInput {
    display: flex;
    align-items: center;
    padding: 4px 14px;
    background: rgba(20, 24, 36, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.index-library-modal-table .treeCustomInput .rs-input {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    width: 70px;
    height: 23px;
    text-align: center;
}

.index-library-modal-table .time-type-radio-group {
    display: flex;
    margin-left: -10px;
}

.index-library-modal-table .time-type-div {
    background: rgba(20, 24, 36, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
    display: flex;
    align-items: center;
    min-height: 35px;
}

.index-library-modal-table .time-type-div .rs-input {
    width: 60px;
    text-align: center;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    height: 23px;
}

.index-library-modal-table .group-list {
    background: rgba(20, 24, 36, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 490px;
    overflow: auto;
}

.index-library-modal-table .group-list .group {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 10px;
}

.index-library-modal-table .group-list .column {
    display: flex;
}

.index-library-modal-table .group-list .column:not(:first-child) {
    margin-top: 5px;
}

.index-library-modal-table .group-list .group.selected {
    background: rgba(0, 129, 156, 0.5);
}

.index-library-modal-table .group-list .square-icon {
    margin-top: 8px;
    width: 7px;
    height: 7px;
    background: #fff;
}

.index-library-modal-table .group-list .column-name {
    margin-left: 10px;
}

.index-library-modal-table .group .more {
    visibility: collapse;
    position: absolute;
    right: 5px;
    bottom: 2px;
    cursor: pointer;
    display: flex;
    background: #141824;
    padding: 0px 15px;
    border-radius: 20px;
    margin-top: -6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.index-library-modal-table .group:hover .more {
    visibility: visible;
}

.index-library-modal-table .rs-icon use {
    color: #00ddff;
}