@mixin betweenX {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@mixin betweenY {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin centerY {
    display: flex;
    align-items: center;
}

@mixin cen-y {
    display: flex;
    align-items: center;
}

@mixin pagination {
    text-align: center;
    margin-top: 16px;
    height: 30px;
}

@mixin aiCenterY {

}

@mixin jc-end-x {
    display: flex;
    justify-content: flex-end;
}

@mixin jcCenterX {

}

@mixin centerX {

}

@mixin centerXY {

}