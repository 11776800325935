.index-constraint-modal .icon-button-column .rs-btn.rs-btn-default {
    background: transparent;
}

.index-constraint-modal .icon-button-column {
    width: 35px;
    text-align: center;
}

.index-constraint-modal .icon-button-column .rs-btn {
    width: 35px;
    padding: 0 !important;
}

.index-constraint-modal .add-button {
    margin-left: 70px;
}