/* Table */
.data-table {
    outline: unset;
}

/* Row */
.data-table .header > div:not(.stock) {
    width: fit-content;
    align-items: flex-end;
}

.data-table .header > div {
    background: #2d313d;
}

.data-table .header:first-child > div {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.data-table:not(.transpose) .header > div:not(.stock) {
    padding-top: 28px;
}

.data-table .odd > div {
    background: #353945;
}

.data-table .even > div {
    background: #2b2f3b;
}

/* Cell */
.data-table .row > div,
.data-table-stock {
    font-size: 14px;  
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    height: 100%;
}

body > .data-table-stock {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.data-table .row > div:not(.stock),
.data-table .serial,
.data-table .code,
.data-table .name,
.data-table-stock .serial,
.data-table-stock .code,
.data-table-stock .name {
    padding: 8px 8px 6px;
    display: flex;
    justify-content: flex-end;
}

.data-table .stock,
.data-table-stock {
    display: flex;
}

.data-table .serial,
.data-table .code,
.data-table .name,
.data-table-stock .serial,
.data-table-stock .code,
.data-table-stock .name {
    align-items: flex-end;
    position: relative;
}

.data-table.transpose .stock {
    flex-direction: column;
}

.data-table.transpose .serial,
.data-table.transpose .code {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.data-table.transpose .serial,
.data-table.transpose .code,
.data-table.transpose .name {
    width: 100%;
}

.data-table.transpose .serial .buttons {
    padding: 2px 0px;
}

.data-table .row > div:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.data-table div.even > div,
.data-table div.odd > div {
    align-items: center;
}

.data-table:not(.transpose) .header div:not(.value) > div:not(.name) {
    justify-content: center;
}

.data-table.transpose .header div:not(.value) {
    align-items: center;
    justify-content: center;
}

.data-table .serial,
.data-table-stock .serial {
    justify-content: center !important;
    color: rgba(255, 255, 255, 0.5);
}

.data-table:not(.transpose) .serial,
.data-table:not(.transpose) .code,
.data-table-stock:not(.transpose) .serial,
.data-table-stock:not(.transpose) .code {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.data-table.transpose .code {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.data-table:not(.transpose) div:not(.header) > .code .rs-icon,
.data-table-stock > .code .rs-icon {
    margin-right: 8px;
}

.data-table.transpose .code .rs-icon {
    margin-bottom: 7px;
}

.data-table:not(.transpose) .name,
.data-table-stock:not(.transpose) .name {
    justify-content: flex-start;
    white-space: nowrap;
}

.data-table.transpose .name {
    justify-content: center;
}

.data-table .row .value {  
    max-width: 120px;
    min-width: 93px;
    text-align: right;
    word-wrap: break-word;
}

.data-table:not(.transpose) .serial,
.data-table-stock:not(.transpose) .serial {
    width: 60px;
}

.data-table:not(.transpose) .code,
.data-table-stock:not(.transpose) .code {
    width: 79px;
}

.data-table:not(.transpose) .name,
.data-table-stock:not(.transpose) .name {
    width: 96px;
}

/* Header buttons */
.data-table .serial .buttons,
.data-table-stock .buttons {
    display: none;
}

.data-table .serial:hover .buttons {
    display: flex;
}

.data-table .serial .buttons .rs-icon:not(:first-child) {
    margin-left: 10px;
}

.data-table .serial:hover .number {
    display: none;
}

.data-table.transpose .row .value {
    padding-left: 26px;
}

.data-table .header-button-container {
    position: absolute;
    visibility: hidden;
}

.data-table:not(.transpose) .header-button-container {
    top: 6px;
    right: 10px;
}

.data-table:not(.transpose) .header-button-container .rs-icon {
    margin-left: 10px;
}

.data-table.transpose .header-button-container {
    top: 10px;
    left: 6px;
    display: flex;
    flex-direction: column;
}

.data-table.transpose .header-button-container .rs-icon {
    margin-bottom: 10px;
}

.data-table .header-button-container .rs-icon {
    cursor: pointer;
}

.data-table .row > div:hover .header-button-container {
    visibility: visible;
}