@mixin table-1() {
    .rs-table-row {
        background: transparent !important;
        border-bottom: 0.25px rgba(255, 255, 255, 0.15) solid !important;
        margin-left: -10px;
    }
    .rs-table-cell-content {
        padding: 6px 0px;
        padding-left: 12px;
    }

    .rs-table-cell-header:not(:nth-of-type(1)) {
        .rs-table-cell-content {
            white-space: normal;
            padding: 0;
            align-items: center;
            justify-content: flex-end !important;
        }
    }

    .rs-table-body-wheel-area {
        .rs-table-cell-first {
            padding-left: 6px;
        }
    }

    .rs-table-cell-header-sortable.rs-table-cell.rs-table-cell-content{
        background-color: black;
    }
}