.highcharts-yaxis-labels.yAxis-0 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(20px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-0 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(20px, 0);
}

.highcharts-yaxis-labels.yAxis-1 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(70px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-1 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(70px, 0);
}

.highcharts-yaxis-labels.yAxis-2 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(120px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-2 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(120px, 0);
}

.highcharts-yaxis-labels.yAxis-3 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(170px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-3 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(170px, 0);
}

.highcharts-yaxis-labels.yAxis-4 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(220px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-4 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(220px, 0);
}

.highcharts-yaxis-labels.yAxis-5 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(270px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-5 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(270px, 0);
}

.highcharts-yaxis-labels.yAxis-6 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(320px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-6 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(320px, 0);
}

.highcharts-yaxis-labels.yAxis-7 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(370px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-7 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(370px, 0);
}

.highcharts-yaxis-labels.yAxis-8 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(420px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-8 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(420px, 0);
}

.highcharts-yaxis-labels.yAxis-9 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(470px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-9 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(470px, 0);
}

.highcharts-yaxis-labels.yAxis-10 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(520px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-10 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(520px, 0);
}

.highcharts-yaxis-labels.yAxis-11 .highcharts-label.highcharts-crosshair-label path {
    transform: translate(570px, 8px) scaleY(0.5);
}

.highcharts-yaxis-labels.yAxis-11 .highcharts-label.highcharts-crosshair-label text {
    transform: translate(570px, 0);
}

.highcharts-yaxis-labels.yAxis-0 ~ .highcharts-yaxis-labels.yAxis-0,
.highcharts-axis.yAxis-0 ~ .highcharts-axis.yAxis-0,
.highcharts-yaxis-labels.yAxis-1 ~ .highcharts-yaxis-labels.yAxis-1,
.highcharts-axis.yAxis-1 ~ .highcharts-axis.yAxis-1,
.highcharts-yaxis-labels.yAxis-2 ~ .highcharts-yaxis-labels.yAxis-2,
.highcharts-axis.yAxis-2 ~ .highcharts-axis.yAxis-2,
.highcharts-yaxis-labels.yAxis-3 ~ .highcharts-yaxis-labels.yAxis-3,
.highcharts-axis.yAxis-3 ~ .highcharts-axis.yAxis-3,
.highcharts-yaxis-labels.yAxis-4 ~ .highcharts-yaxis-labels.yAxis-4,
.highcharts-axis.yAxis-4 ~ .highcharts-axis.yAxis-4,
.highcharts-yaxis-labels.yAxis-5 ~ .highcharts-yaxis-labels.yAxis-5,
.highcharts-axis.yAxis-5 ~ .highcharts-axis.yAxis-5,
.highcharts-yaxis-labels.yAxis-6 ~ .highcharts-yaxis-labels.yAxis-6,
.highcharts-axis.yAxis-6 ~ .highcharts-axis.yAxis-6,
.highcharts-yaxis-labels.yAxis-7 ~ .highcharts-yaxis-labels.yAxis-7,
.highcharts-axis.yAxis-7 ~ .highcharts-axis.yAxis-7,
.highcharts-yaxis-labels.yAxis-8 ~ .highcharts-yaxis-labels.yAxis-8,
.highcharts-axis.yAxis-8 ~ .highcharts-axis.yAxis-8,
.highcharts-yaxis-labels.yAxis-9 ~ .highcharts-yaxis-labels.yAxis-9,
.highcharts-axis.yAxis-9 ~ .highcharts-axis.yAxis-9,
.highcharts-yaxis-labels.yAxis-10 ~ .highcharts-yaxis-labels.yAxis-10,
.highcharts-axis.yAxis-10 ~ .highcharts-axis.yAxis-10,
.highcharts-yaxis-labels.yAxis-11 ~ .highcharts-yaxis-labels.yAxis-11,
.highcharts-axis.yAxis-11 ~ .highcharts-axis.yAxis-11 {
    visibility: hidden;
}