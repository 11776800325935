.alertBox {
    padding: 10px;
    min-width: 243px;
    max-width: 450px;
    color: black;
    background-color: #D4D8DC;
    position: absolute;
    z-index: 12;
    right: 25px;
    top: 52px;
    border-radius: 6px;
    z-index: 6;
    padding-right: 30px;
}
.alertBox_arrow {
    position: absolute;
    right: 12px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 10px 6px;
    border-color: transparent transparent #D4D8DC transparent;
}
.alertBox_close {
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.alertBox_row {
    display: flex;
    justify-content: space-between;
}
.alertBox_row_title {
    max-width: 356px;
    width: max-content;
    display: flex;
}
.alertBox_row_title > div:nth-of-type(1) {
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertBox_row_title > div:nth-of-type(1) .rs-icon > svg {
    width: 16px;
    height: 16px;
}
.alertBox_row_title > div:nth-of-type(2) a {
    color: #141824;
}
.alertBox_row_title > div:nth-of-type(2) a:hover {
    color: #141824;
    text-decoration: none;
}
.alertBox_row_title > div:nth-of-type(2) a:focus {
    text-decoration: none;
}
.alertBox_row_status {
    padding-left: 10px;
    word-break: keep-all;
}
