.performance-graph .graph-title {
    text-align: center;
}

.performance-graph .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 7px;
}

.performance-graph .legend-icon {
    height: 2px;
    width: 16px;
    margin-right: 6px;
}

.performance-graph .legend-icon:not(:first-child) {
    margin-left: 21px;
}

.performance-graph .legend-icon.blue {
    background: #00ddff;
}

.performance-graph .legend-icon.orange {
    background: #ff9600;
}

.performance-graph .highcharts-container, .highcharts-container svg {
    width: 100% !important;
}