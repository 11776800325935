.strategy__list {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.strategy__list-empty > div:nth-of-type(1){
    font-size: 14px;    
    color: hsla(0,0%,100%,.5);
}

.strategy__list-empty > div:nth-of-type(2){
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.strategy__list-empty > div:nth-of-type(2) a:hover,
.strategy__list-empty > div:nth-of-type(2) a:active,
.strategy__list-empty > div:nth-of-type(2) a:visited{
    
    text-decoration: none;
}
