.index-button {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear !important;
}

.rs-input-group:hover > .index-button {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear !important;
}

.rs-input:focus + .index-button {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear !important;
}